import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UseBuySelectorStore from '../store/BuySelector';

const IMPSCheckout = () => {
    const { globalCurrency } = UseBuySelectorStore()
    const location = useLocation();
    const paymentData = location.state; // This is the data you passed
    const navigate = useNavigate()
    const handleConfirm = () => {
        navigate('/confirm')
    };
    console.log(paymentData)
    return (
        <div className="flex flex-col h-[65vh] justify-between">
            {/* Content */}
            <div className="overflow-y-auto p-4 space-y-5">
                {/* Bank Information Section */}
                <div className="space-y-5">
                    <h2 className="text-md font-bold text-gray-900">Bank Information</h2>

                    <div className="space-y-8">
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">You Send</span>
                            <span className="text-sm font-medium text-gray-800">{globalCurrency?.currencySymbol} {paymentData?.amount}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">Transaction Type</span>
                            <span className="text-sm font-medium text-gray-800">{paymentData?.type}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">Account Name</span>
                            <span className="text-sm font-medium text-gray-800">
                                {paymentData?.bank?.name}
                            </span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">Account Number</span>
                            <span className="text-sm font-medium text-gray-800">{paymentData?.bank["Account Number"]}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">IFSC Code</span>
                            <span className="text-sm font-medium text-gray-800">{paymentData?.bank?.ifsc}</span>
                        </div>
                        <div className="flex justify-between items-center border-b pb-2">
                            <span className="text-sm text-green-500">Bank Name</span>
                            <span className="text-sm font-medium text-gray-800">{paymentData?.bank?.bank}</span>
                        </div>
                    </div>
                </div>

                {/* Bank Notes Section */}
                <div className="space-y-5">
                    <h2 className="text-md font-bold text-gray-900">Bank Notes</h2>

                    <ul className="space-y-5 list-disc pl-6 text-sm text-gray-700">
                        {
                            paymentData && paymentData?.bankNotes?.map((item, index) => (
                                <li key={index}>{item?.msg}</li>
                            ))
                        }
                    </ul>
                </div>
            </div>

            {/* Confirm Button */}
            <div className="p-4">
                <button
                    onClick={handleConfirm}
                    className="w-full bg-green-500 text-white py-3 rounded-lg text-md font-bold hover:bg-green-600 transition duration-200"
                >
                    Confirm
                </button>
            </div>
        </div>
    );
};

export default IMPSCheckout;
