import React from 'react';
import { FiMenu } from 'react-icons/fi';
import logo from '../assets/logo.jpeg';

const Header = ({ onMenuClick }) => {
  return (
    <div className="bg-white p-1 rounded-t-lg flex items-center justify-between">
      <img src={logo} alt="Logo" className="h-14 w-auto ml-0" />
      <FiMenu className="text-2xl cursor-pointer mr-4" onClick={onMenuClick} />
    </div>
  );
};

export default Header;
