import React, { useState, useEffect } from 'react';
import { showToast } from '../utils/toast';
import authFetch from '../utils/authFetch';
import { FiLock } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const handleLogin = async (e) => {
        e.preventDefault();

        if (!email || !password) {
            showToast("Please enter both email and password.", 'error');
            return;
        }

        try {
            const response = await authFetch('/api/v1/user/login', {
                method: 'POST',
                body: {
                    emailId: email,
                    password,
                },
            });

            if (response.message) {
                showToast(response.message, 'success');
                onLogin(response);
            } else {
                showToast(response.error, 'error');
            }
        } catch (err) {
            console.log("handleLogin", err);
        }
    };

    const onLogin = async (response) => {
        try {
            localStorage.setItem("userToken", response.token);
            navigate('/profile')
        } catch (err) {
            console.log("onLogin", err);
        }
    };

    return (
        <div className="flex flex-col justify-center h-full w-full">
            <div className="flex items-center justify-center mb-6">
                <FiLock className="text-green-500 h-8 w-8" />
                <h2 className="text-3xl font-bold text-left text-gray-800 ml-2">Login</h2>
            </div>
            <form onSubmit={handleLogin} className="space-y-6 w-full">
                <div className="w-full">
                    <label htmlFor="email" className="block text-sm font-semibold text-gray-700 mb-2">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold"
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <div className="w-full">
                    <label htmlFor="password" className="block text-sm font-semibold text-gray-700 mb-2">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold"
                        placeholder="Enter your password"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-green-500 text-white rounded-lg py-3 font-bold text-lg hover:bg-green-600 transition duration-200"
                >
                    Login
                </button>
            </form>
            <div className="mt-4 text-center">
                <button
                    // TODO: will navigate to forgetpassword component
                    className="text-sm text-blue-500 hover:underline"
                >
                    Forgot your password?
                </button>
            </div>
            <div className="mt-6 text-center w-full">
                <p className="text-sm text-gray-600">
                    Don't have an account?{' '}
                    <Link
                        to={'/signup'}
                        className="text-green-500 font-semibold hover:underline"
                    >
                        Signup
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default Login;
