import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, redirectTo = "/login" }) => {
    const userToken = localStorage.getItem('userToken');

    return userToken ? <Component /> : <Navigate to={redirectTo} />;
};

export default PrivateRoute;
