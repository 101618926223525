import { create } from 'zustand';

const UseBuySelectorStore = create((set) => ({
    globalCurrency: null,
    globalCoin: null,
    globalNetwork: null,
    globalYouSend: '',
    globalYouGet: '',
    globalWalletAddress: '',
    globalQuoteData: null,
    globalPaymentMethod: 'IMPS',
    setGlobalCurrency: (data) => set({ globalCurrency: data }), // Function to set currency data
    setGlobalCoin: (data) => set({ globalCoin: data }), // Function to set coin data
    setGlobalNetwork: (data) => set({ globalNetwork: data }), // Function to set network data
    setGlobalYouSend: (data) => set({ globalYouSend: data }), // Function to set you send data
    setGlobalYouGet: (data) => set({ globalYouGet: data }), // Function to set you get data
    setGlobalWalletAddress: (data) => set({ globalWalletAddress: data }), // Function to set you get data
    setGlobalQuoteData: (data) => set({ globalQuoteData: data }), // Function to set quote data
    setGlobalPaymentMethod: (data) => set({ globalPaymentMethod: data }), // Function to set payment method 
    clearGlobalCurrency: () => set({ globalCurrency: null }),  // Function to clear currency 
    clearGlobalCoin: () => set({ globalCoin: null }),  // Function to clear coin 
    clearGlobalNetwork: () => set({ globalNetwork: null }),  // Function to clear network 
}));

export default UseBuySelectorStore;
