import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import CurrencySelector from './CurrencySelector'; // Import the CurrencySelector component
import CoinSelector from './CoinSelector';
import '../styles/Form.css'; // Import your CSS for transitions
import authFetch from '../utils/authFetch';
const Sell = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [focusedInput, setFocusedInput] = useState('sell');
    const [isSelectorOpen, setIsSelectorOpen] = useState(false);
    const [isCoinSelectorOpen, setIsCoinSelectorOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currencies, setCurrencies] = useState([])
    const [coins, setCoins] = useState([])
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [filteredCurrency, setFilteredCurrency] = useState([])
    const [selectedCoin, setSelectedCoin] = useState();
    const [filteredCoin, setFilteredCoin] = useState([])
    const [youGetValue, setYouGetValue] = useState(''); // Add state for "You get" value
    const sellInputRef = useRef(null);


    useEffect(() => {
        sellInputRef.current.focus();
    }, []);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const preventNegative = (e) => {
        if (e.target.value < 0) {
            e.target.value = 0;
        }
    };

    const handleCoinSelect = (coin) => {
        setSelectedCoin(coin);
        setIsCoinSelectorOpen(false);
    };

    const handleCurrencySelect = (currency) => {
        setSelectedCurrency(currency);
        setIsSelectorOpen(false);
    };
    const handleButtonClick = (e, amount) => {
        e.preventDefault()
        setYouGetValue(amount);
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setYouGetValue(value);
        }
    };

    const getAllCurrencies = async () => {
        try {
            const response = await authFetch('/api/v1/user/getAllCurrencies', {
                method: 'GET',
            });
            if (!response?.code === 200) return
            const currencyData = response?.data
            setCurrencies(currencyData);
            setSelectedCurrency(currencyData[0])
        } catch (error) {
            console.error('Error fetching getAllCurrencies:', error);
        }
    };

    const getAllCoins = async () => {
        try {
            const response = await authFetch('/api/v1/user/getAllCoins', {
                method: 'GET',
            });
            if (!response?.code === 200) return
            const coinData = response?.data
            setCoins(coinData);
            setSelectedCoin(coinData[0])
        } catch (error) {
            console.error('Error fetching getAllCoins:', error);
        }
    };

    useEffect(() => {
        getAllCurrencies()
        getAllCoins()
    }, [])
    useEffect(() => {
        // Filter currencies based on search term whenever searchTerm or currencies change
        let filteredFiat = currencies.filter(currency =>
            currency?.country.toLowerCase().includes(searchTerm.toLowerCase()) ||
            currency?.fiatSymbol.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCurrency(filteredFiat);

        let filteredCrypto = coins.filter(coin =>
            coin.coinName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            coin.coin.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredCoin(filteredCrypto)
    }, [searchTerm, currencies, coins]);
    return (
        <div>
            <form className="space-y-6 text-sm mt-4">
                {/* Sell Section */}
                <div className="space-y-3">
                    <label className="block text-black font-semibold">Sell</label>
                    <div
                        className={`flex items-center border-2 ${focusedInput === 'sell' ? 'border-green-500' : 'border-gray-300'} rounded-lg p-1 bg-white`}
                    >
                        <input
                            ref={sellInputRef}
                            type="number"
                            placeholder=''
                            className="bg-white text-black text-lg font-semibold flex-1 outline-none p-3 appearance-none"
                            onFocus={() => setFocusedInput('sell')}
                            onBlur={() => setFocusedInput('')}
                            onInput={preventNegative}
                        />
                        <div onClick={() => setIsCoinSelectorOpen(true)} className="flex items-center space-x-2 bg-gray-100 p-4 rounded-r-lg">
                            <img src={selectedCoin?.coinIcon} alt="Coin Icon" className="h-5 w-5 rounded-full object-cover" />
                            <span className="text-gray-500 uppercase">{selectedCoin?.coin}</span>
                            <FaChevronDown className="text-gray-500" />
                        </div>
                    </div>
                </div>

                {/* You Get Section */}
                <div className="space-y-2">
                    <label className="block text-black font-semibold">You get</label>
                    <div
                        className={`flex items-center border-2 ${focusedInput === 'get'
                            ? youGetValue && youGetValue < 1050
                                ? 'border-red-500'
                                : 'border-green-500'
                            : 'border-gray-300'
                            } rounded-lg p-1 bg-white`}
                    >
                        <input
                            type="number"
                            value={youGetValue}
                            placeholder={`min ${selectedCurrency?.currencySymbol}1050`}
                            className="bg-white text-black text-lg font-semibold flex-1 outline-none p-3 appearance-none"
                            onFocus={() => setFocusedInput('get')}
                            onBlur={() => setFocusedInput('')}
                            onInput={preventNegative}
                            onChange={handleInputChange}
                        />
                        <div onClick={() => setIsSelectorOpen(true)} className="flex items-center space-x-2 bg-gray-100 p-4 rounded-r-lg">
                            <img src={selectedCurrency?.flagUrl} alt="Currency Flag" className="h-5 w-5 rounded-full object-cover" />
                            <span className="text-gray-500">{selectedCurrency?.fiatSymbol}</span>
                            <FaChevronDown className="text-gray-500" />
                        </div>
                    </div>
                    {youGetValue && youGetValue < 1050 && (
                        <p className="text-red-500 text-sm font-semibold">Min amount is {selectedCurrency.currencySymbol}1050</p>
                    )}
                    <div className="flex space-x-2">
                        <button
                            className="bg-gray-100 border border-gray-300 rounded-lg px-3 py-1 text-center text-gray-600 font-semibold h-8 w-20 text-sm"
                            onClick={(e) => handleButtonClick(e, 1050)}
                        >
                            ₹1,000
                        </button>
                        <button
                            className="bg-gray-100 border border-gray-300 rounded-lg px-3 py-1 text-center text-gray-600 font-semibold h-8 w-20 text-sm"
                            onClick={(e) => handleButtonClick(e, 5000)}
                        >
                            ₹5,000
                        </button>
                        <button
                            className="bg-gray-100 border border-gray-300 rounded-lg px-3 py-1 text-center text-gray-600 font-semibold h-8 w-20 text-sm"
                            onClick={(e) => handleButtonClick(e, 10000)}
                        >
                            ₹10,000
                        </button>
                    </div>
                </div>


                {/* Conversion Rate */}
                <div className="text-xs text-gray-500 flex justify-between -mt-1">
                    <span>1 USDT ≈ ₹X.XX</span>
                </div>

                {/* You Pay Section */}
                <div className="border border-gray-300 rounded-lg bg-gray-100">
                    <div className="p-4 flex justify-between items-center cursor-pointer" onClick={toggleExpand}>
                        <span className="text-black font-semibold">You get ₹0</span>
                        {isExpanded ? <FaChevronUp className="text-gray-700" /> : <FaChevronDown className="text-gray-700" />}
                    </div>
                    <CSSTransition
                        in={isExpanded}
                        timeout={300}
                        classNames="expand"
                        unmountOnExit
                    >
                        <div className="px-4 pb-4 space-y-2">
                            <div className="flex justify-between pb-2">
                                <span>Network fee <span className="ml-1 text-gray-400">(0 USDT)</span></span>
                                <span>₹0</span>
                            </div>
                            <div className="flex justify-between pb-2">
                                <span className="flex items-center">Platform Fee</span>
                                <span>₹0</span>
                            </div>
                            <div className="flex justify-between pb-2">
                                <span>Total fee</span>
                                <span>₹0</span>
                            </div>
                            <span className="text-xs text-gray-400">Note: TDS of 1% is levied by Income Tax department, GoI.</span>
                        </div>
                    </CSSTransition>
                </div>

                {/* Proceed Button */}
                <button className="w-full bg-green-500 text-white rounded-lg py-4 font-bold text-lg flex items-center justify-center space-x-2 h-14">
                    <span>Proceed</span>
                </button>
            </form>

            {/* Currency Selector */}
            <CurrencySelector
                isOpen={isSelectorOpen}
                onClose={() => { setIsSelectorOpen(false); setSearchTerm('') }}
                currencies={filteredCurrency}
                onSelect={handleCurrencySelect}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
            />
            {/* Coin Selector */}
            <CoinSelector
                isOpen={isCoinSelectorOpen}
                onClose={() => { setIsCoinSelectorOpen(false); setSearchTerm('') }}
                coins={filteredCoin}
                onSelect={handleCoinSelect}
                setSearchTerm={setSearchTerm}
                searchTerm={searchTerm}
            />
        </div>
    );
};

export default Sell;
