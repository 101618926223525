import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ component: Component, redirectTo = "/profile" }) => {
    const userToken = localStorage.getItem('userToken');

    return userToken ? <Navigate to={redirectTo} /> : <Component />;
};

export default PublicRoute;
