import React, { useState } from 'react';
import Header from './Header';
import MainContainer from './MainContainer';
import Buy from './Buy';
import Sell from './sell';
import SideMenu from './SideMenu';
import Login from './Login';
import Signup from './Signup';
import Profile from './Profile';
import Logout from './Logout';
import AddPhone from './AddPhone';
import Kyc from './Kyc';
import PrivateRoute from './PrivateRoute'; // Import PrivateRoute
import PublicRoute from './PublicRoute'; // Import PublicRoute
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import WalletAddress from './WalletAddress';
import IMPSCheckout from './IMPSCheckout';
import UPICheckout from './UPICheckout';
import OrderConfirmed from './OrderConfirmed';
import BuyHistory from './BuyHistory';
import HistoryDetail from './HistoryDetail';

const Widget = ({ fullScreen = false }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className={`relative ${fullScreen ? 'h-screen w-screen' : ''}`}>
      <MainContainer fullScreen={fullScreen}>
        <Router>
          <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
          <Header onMenuClick={toggleMenu} />
          <div className="mt-4">
            <Routes>
              <Route path="/" element={<Buy />} />
              <Route path="/buy" element={<Buy />} />
              <Route path="/sell" element={<Sell />} />
              <Route
                path="/login"
                element={<PublicRoute component={Login} redirectTo="/profile" />}
              />
              <Route
                path="/signup"
                element={<PublicRoute component={Signup} redirectTo="/profile" />}
              />
              <Route
                path="/wallet-address"
                element={<PrivateRoute component={WalletAddress} redirectTo="/login" />}
              />
              <Route
                path="/imps-checkout"
                element={<PrivateRoute component={IMPSCheckout} redirectTo="/login" />}
              />
              <Route
                path="/upi-checkout"
                element={<PrivateRoute component={UPICheckout} redirectTo="/login" />}
              />
              <Route
                path="/buy-history"
                element={<PrivateRoute component={BuyHistory} redirectTo="/login" />}
              />
              <Route
                path="/history-detail"
                element={<PrivateRoute component={HistoryDetail} redirectTo="/login" />}
              />
              <Route
                path="/confirm"
                element={<PrivateRoute component={OrderConfirmed} redirectTo="/login" />}
              />
              <Route
                path="/profile"
                element={<PrivateRoute component={Profile} redirectTo="/login" />}
              />
              <Route
                path="/logout"
                element={<PrivateRoute component={Logout} redirectTo="/login" />}
              />
              <Route
                path="/addPhone"
                element={<PrivateRoute component={AddPhone} redirectTo="/login" />}
              />
              <Route
                path="/kyc"
                element={<PrivateRoute component={Kyc} redirectTo="/login" />}
              />
              {/* Redirect unknown routes to the default view */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </Router>
      </MainContainer>
    </div>
  );
};

export default Widget;

