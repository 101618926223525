import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useProfileStore from '../store/profileStore'
function Logout() {
    const { clearGlobalProfile } = useProfileStore()
    const navigate = useNavigate()
    useEffect(() => {
        clearGlobalProfile()
        localStorage.removeItem("userToken")
        navigate('/login')
    }, [])
    return (
        <div></div>
    )
}

export default Logout