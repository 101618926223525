import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaClock, FaTimesCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import authFetch from '../utils/authFetch';
import { useNavigate } from 'react-router-dom';
import { convertToIST } from '../utils/dateTimeFormat';
const BuyHistory = () => {
    const navigate = useNavigate()
    const [buyHistoryTx, setBuyHistoryTx] = useState([])
    const [loading, setLoading] = useState(true)
    const getBuyHistory = async () => {
        try {
            const response = await authFetch('/api/v1/onramp/transaction', {
                method: 'GET',
            });

            if (response?.code === 200) {
                setBuyHistoryTx(response?.data);
            } else {
                navigate('/login')
            }
        } catch (error) {
            console.error('Error fetching getBuyHistory:', error);
            navigate('/login')
        } finally {
            setLoading(false);
        }
    };
    const getStatusIcon = (status) => {
        if (status === 'Success' || status === "SUCCESS") {
            return <FaCheckCircle className="text-green-500 text-xl" />;
        } else if (status === 'Pending' || status === 'PENDING' || status === 'FIAT_DEPOSIT_RECEIVED' || status === 'TRADE_COMPLETED' || status === 'ON_CHAIN_INITIATED') {
            return <FaClock className="text-yellow-500 text-xl" />;
        } else if (status === 'Failed' || 'FAILED') {
            return <FaTimesCircle className="text-red-500 text-xl" />;
        }
    };
    useEffect(() => {
        getBuyHistory()
    }, [])
    return (
        <div className="p-4 space-y-4 h-full">
            {/* Transaction List */}
            <ul className="space-y-2">
                {buyHistoryTx && buyHistoryTx.map((transaction, index) => (
                    <Link
                        to={"/history-detail"}
                        state={transaction}
                    >
                        <li
                            key={index}
                            className="flex justify-between items-center border-b py-4 px-4 hover:bg-gray-100 transition-colors duration-200 rounded-lg"
                        >
                            <div className="flex items-center space-x-4">
                                {getStatusIcon(transaction?.status)}
                                <div className="space-y-1">
                                    <p className="font-medium">
                                        {transaction?.transaction_id.length > 17
                                            ? `${transaction?.transaction_id.substring(0, 17)}...`
                                            : transaction?.transaction_id}
                                    </p>

                                    <p className="text-gray-500 text-sm">Status: {transaction?.status.charAt(0).toUpperCase() + transaction?.status.slice(1).toLowerCase()}</p>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-gray-500 text-xs">
                                    {convertToIST(transaction?.date).date}
                                </span>
                                <span className="text-gray-500 text-xs">
                                    {convertToIST(transaction?.date).time}
                                </span>
                            </div>

                        </li>
                    </Link>

                ))}
            </ul>
        </div>
    );
};

export default BuyHistory;
