export function convertToIST(dateString) {
    const dateObj = new Date(dateString);

    // Convert the date object to IST
    const optionsDate = { day: '2-digit', month: 'short', timeZone: 'Asia/Kolkata' };
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'Asia/Kolkata' };

    // Format the date and time
    const formattedDate = dateObj.toLocaleDateString('en-IN', optionsDate); // e.g., 11 Sep 2024
    const formattedTime = dateObj.toLocaleTimeString('en-IN', optionsTime); // e.g., 02:20 am/pm

    return {
        date: formattedDate,
        time: formattedTime
    };
}