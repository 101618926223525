import React from 'react'

function offramp() {
    return (
        <iframe
            src={"https://offramp.gatefi.com/?partnerAccountId=fd8544f4-95d9-4ca6-8f36-2f5583b25235&themeMode=light"}
            title="Offramp"
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
                margin: 0,
                padding: 0,
                overflow: "hidden",
                zIndex: 999999,
            }}
        />
    )
}

export default offramp