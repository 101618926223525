import React, { useState, useEffect } from 'react';
import { FiLock } from 'react-icons/fi';
import authFetch from '../utils/authFetch';
import { showToast } from '../utils/toast';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const Signup = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSignup = async (e) => {
        e.preventDefault();

        if (!email || !password || !confirmPassword) {
            showToast('Please fill in all the fields.', 'error');
            return;
        }

        if (password !== confirmPassword) {
            showToast('Passwords do not match.', 'error');
            return;
        }

        try {
            const response = await authFetch('/api/v1/user/signup', {
                method: 'POST',
                body: {
                    emailId: email,
                    password,
                },
            });

            if (response?.message) {
                showToast(response.message, 'success');
                onSignup(response);
            } else {
                showToast(response.error, 'error');
            }
        } catch (err) {
            console.error('handleSignup', err);
        }
    };

    const onSignup = async (response) => {
        try {
            if (response.message) {
                navigate('/login')
            }
        } catch (err) {
            console.error('onSignup', err);
        }
    };

    return (
        <div className="flex flex-col justify-between h-full w-full">
            <div className="flex-1 overflow-auto">
                <div className="flex items-center justify-center mb-6">
                    <FiLock className="text-green-500 h-8 w-8" />
                    <h2 className="text-3xl font-bold text-left text-gray-800 ml-2">Signup</h2>
                </div>
                <form onSubmit={handleSignup} className="space-y-6 w-full">
                    <div className="w-full">
                        <label htmlFor="email" className="block text-sm font-semibold text-gray-700 mb-2">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold"
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="password" className="block text-sm font-semibold text-gray-700 mb-2">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold"
                            placeholder="Create a password"
                            required
                        />
                    </div>
                    <div className="w-full">
                        <label htmlFor="confirmPassword" className="block text-sm font-semibold text-gray-700 mb-2">Confirm Password</label>
                        <input
                            type="password"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="w-full p-3 border border-gray-300 rounded-lg text-black focus:outline-none focus:border-green-500 text-lg font-semibold"
                            placeholder="Confirm your password"
                            required
                        />
                    </div>
                    <div className="flex items-center mt-4">
                        <input
                            id="terms"
                            name="terms"
                            type="checkbox"
                            required
                            className="h-6 w-6 text-blue-600 border-gray-300 rounded ml-2"
                        />
                        <label htmlFor="terms" className="ml-4 text-sm text-gray-700">
                            I agree to the{' '}
                            <a href="#" className="text-blue-500 font-semibold">
                                Terms and Conditions
                            </a>{' '}
                            and{' '}
                            <a href="#" className="text-blue-500 font-semibold">
                                Privacy Policy
                            </a>
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-green-500 text-white rounded-lg py-3 font-bold text-lg hover:bg-green-600 transition duration-200 mt-4"
                    >
                        Signup
                    </button>
                </form>
                <div className="mt-6 text-center w-full">
                    <p className="text-sm text-gray-600">
                        Already have an account?{' '}
                        <Link
                            to={'/login'}
                            className="text-green-500 font-semibold hover:underline"
                        >
                            Login
                        </Link>
                    </p>
                </div>
            </div>
            <div className="mt-8 text-center w-full px-4 py-3 bg-gray-100 rounded-lg">
                <div className="flex items-center justify-center">
                    <FiLock className="h-5 w-5 mr-2 text-gray-600" />
                    <div className="text-xs text-gray-600">
                        <p>Securely stored, no spam</p>
                        <p>Your email will not be shared or used for spam emails/messages</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
