import React, { useState, useEffect } from 'react';
import { FaCheck } from 'react-icons/fa'; // Import the check icon from react-icons
import useProfileStore from '../store/profileStore';
const KycProgress = () => {
    // State to manage the completion of steps
    const { globalProfile } = useProfileStore()
    const [completedSteps, setCompletedSteps] = useState({
        basicKYC: false, // Default to false until data is loaded
        videoKYC: false,
        edd: false,
    });
    useEffect(() => {
        if (globalProfile?.kyc) {
            const { basic, intermediate, advanced, completed } = globalProfile.kyc;

            setCompletedSteps({
                basicKYC: !!basic, // Mark as true if basic KYC is completed
                videoKYC: !!intermediate, // Mark as true if intermediate is true
                edd: !!(advanced && completed), // Mark as true if both advanced and completed are true
            });
        }
    }, [globalProfile]);

    return (
        <div className="relative flex justify-between mt-6">
            {/* Progress line */}
            <div className="absolute top-4 left-[8%] w-[85%] border-t-2 border-gray-300 z-0"></div>

            {/* Step 1: Basic KYC */}
            <div className="relative z-10 text-center">
                <div className={`h-8 w-8 border-2 rounded-full flex items-center justify-center ${completedSteps.basicKYC ? 'border-green-500 bg-green-500' : 'border-gray-300 bg-white'}`}>
                    {completedSteps.basicKYC && <FaCheck className="text-white" />}
                </div>
                <p className="text-sm mt-2">Basic KYC</p>
            </div>

            {/* Step 2: Video KYC */}
            <div className="relative z-10 text-center">
                <div className={`h-8 w-8 border-2 rounded-full flex items-center justify-center ${completedSteps.videoKYC ? 'border-green-500 bg-green-500' : 'border-gray-300 bg-white'}`}>
                    {completedSteps.videoKYC && <FaCheck className="text-white" />}
                </div>
                <p className="text-sm mt-2">Video KYC</p>
            </div>

            {/* Step 3: EDD */}
            <div className="relative z-10 text-center">
                <div className={`h-8 w-8 border-2 rounded-full flex items-center justify-center ${completedSteps.edd ? 'border-green-500 bg-green-500' : 'border-gray-300 bg-white'}`}>
                    {completedSteps.edd && <FaCheck className="text-white" />}
                </div>
                <p className="text-sm mt-2">EDD</p>
            </div>
        </div>
    );
};

export default KycProgress;
